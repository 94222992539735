require("./scss/home.scss");

const langSec = document.querySelector('.languageSection');
const buttons = document.querySelectorAll('#btntranslate');
// banner
const textTitle = document.getElementById('title1');
const continuaTitle = document.getElementById('contTitle');
const textDesc = document.getElementById('description');


const textTitle2 = document.getElementById('title2');
const continuaTitle2 = document.getElementById('contTitle2');
const textDesc2 = document.getElementById('description2');

const textTitle3 = document.getElementById('title3');
const continuaTitle3 = document.getElementById('contTitle3');
const textDesc3 = document.getElementById('description3');

// menu

const textMenu = document.querySelector('.menu');
const textMenu1 = document.querySelector('.menu1');
const textMenu2 = document.querySelector('.menu2');
const textMenu3 = document.querySelector('.menu3');
const textMenu4 = document.querySelector('.menu4');
const textMenu5 = document.querySelector('.menu5');
const textMenu6 = document.querySelector('.menu6');
const textMenu7 = document.querySelector('.menu7');
const textMenu8 = document.querySelector('.menu8');
const textMenu9 = document.querySelector('.menu9');
const textMenu10 = document.querySelector('.menu10'); 
const textMenu11 = document.querySelector('.menu11'); 
const textMenu12 = document.querySelector('.menu12'); 
const textMenu13 = document.querySelector('.menu13'); 
const textMenu14 = document.querySelector('.menu14'); 
const textMenu15 = document.querySelector('.menu15'); 
const textMenu16 = document.querySelector('.menu16'); 
const textMenu17 = document.querySelector('.menu17'); 
const textMenu18 = document.querySelector('.menu18'); 

//parte1
const textSobre = document.querySelector('.sobre');
const textSobre1 = document.querySelector('.sobre1');
const textSobre2 = document.querySelector('.sobre2');
const textSobre3 = document.querySelector('.sobre3');
const textSobre4 = document.querySelector('.sobre4');
const textSobre5 = document.querySelector('.sobre5');

// veticais

const textvert1 = document.querySelector('.vert1');
const textvert2 = document.querySelector('.vert2');
const textvert3 = document.querySelector('.vert3');
const textvert4 = document.querySelector('.vert4');
const textvert5 = document.querySelector('.vert5');
const textvert6 = document.querySelector('.vert6');
const textvert7 = document.querySelector('.vert7');
const textvert8 = document.querySelector('.vert8');

buttons.forEach(button => {
  button.addEventListener('click', () => {
    langSec.querySelector('.active').classList.remove('active');
    button.classList.add('active');

    const attr = button.getAttribute('language');

    textTitle.textContent = data[attr].title1;
    continuaTitle.textContent = data[attr].contTitle;
    textDesc.textContent = data[attr].description;

    textTitle2.textContent = data[attr].title2;
    continuaTitle2.textContent = data[attr].contTitle2;
    textDesc2.textContent = data[attr].description2;

    textTitle3.textContent = data[attr].title3;
    continuaTitle3.textContent = data[attr].contTitle3;
    textDesc3.textContent = data[attr].description3;

     textMenu.textContent = data[attr].menu;
     textMenu1.textContent = data[attr].menu1;
     textMenu2.textContent = data[attr].menu2;
     textMenu3.textContent = data[attr].menu3;
     textMenu4.textContent = data[attr].menu4;
     textMenu5.textContent = data[attr].menu5;
     textMenu6.textContent = data[attr].menu6;
     textMenu7.textContent = data[attr].menu7;
     textMenu8.textContent = data[attr].menu8;
     textMenu9.textContent = data[attr].menu9;
     textMenu10.textContent = data[attr].menu10;
     textMenu11.textContent = data[attr].menu11;
     textMenu12.textContent = data[attr].menu12;
     textMenu13.textContent = data[attr].menu13;
     textMenu14.textContent = data[attr].menu14;
     textMenu15.textContent = data[attr].menu15;
     textMenu16.textContent = data[attr].menu16;
     textMenu17.textContent = data[attr].menu17;
     textMenu18.textContent = data[attr].menu18;

    textSobre.textContent = data[attr].sobre;
    textSobre1.textContent = data[attr].sobre1;
    textSobre2.textContent = data[attr].sobre2;
    textSobre3.textContent = data[attr].sobre3;
    textSobre4.textContent = data[attr].sobre4;
    textSobre5.textContent = data[attr].sobre5;
  

    textvert1.textContent = data[attr].vert1;
    textvert2.textContent = data[attr].vert2;
    textvert3.textContent = data[attr].vert3;
    textvert4.textContent = data[attr].vert4;
    textvert5.textContent = data[attr].vert5;
    textvert6.textContent = data[attr].vert6;
    textvert7.textContent = data[attr].vert7;
    textvert8.textContent = data[attr].vert8;
  });
});

const data = {
  "portuguese":
  {
    "menu": "Soluções",
    "menu1": "Cases",
    "menu2": "Sobre nós",
    "menu3": "Carreira",
    "menu4": "Contato",
    "menu5": "Compliance",
    "menu6": "Segurança da informação",
    "menu7": "Conectividade e Infra",
    "menu8": "Sistemas e Cloud",
    "menu9": "Segurança Pública e Privada",
    "menu10": "Cabling e Automação",
    "menu11": "Centro de Serviços e Operações(NOC e SOC)",
    "menu12": "Professional Services",
    "menu13": "Healthcare",
    "menu14": "Varejo",
    "menu15": "Professional Services",
    "menu16": "Logística",
    "menu17": "Serviços Financeiros",
    "menu18": "Facilities",
    "title1": "Mais que um",
    "contTitle": "fornecedor,",
    "description": "somos parceiros de negócio.",
    "title2": "DISPONÍVEIS",
    "contTitle2": "E SEGURAS",
    "description2": "OPERAÇÕES MAIS",
    "title3": "INTELIGENTES",
    "contTitle3": "E INOVADORAS",
    "description3": "INFRAESTRUTURAS",
    "sobre": "Ha mais de 9 anos,",
    "sobre1": "a Hexa vem se destacando no cenário nacional",
    "sobre2": "MELHOR PARCEIRO",
    "sobre3": "DE TECNOLOGIA",
    "sobre4": "DE DIVERSAS EMPRESAS",
    "sobre5": "Saiba mais",
    
    "vert1": "VERTICAIS QUE ATUAMOS:",
    "vert2": "HEALTHCARE",
    "vert3": "VAREJO",
    "vert4": "PROFESSIONAL SERVICES",
    "vert5": "Para quem fazemos?",
    "vert6": "LOGÍSTICA",
    "vert7": "SERVIÇOS FINANCEIROS",
    "vert8": "FACILITIES",
  },
  "english":
  {
    "menu": "Solutions",
    "menu1": "Cases",
    "menu2": "About us",
    "menu3": "Career",
    "menu4": "Contact",
    "menu5": "Compliance",
    "menu6": "Information security",
    "menu7": "Connectivity and Infra",
    "menu8": "Systems and Cloud",
    "menu9": "Public and Private Security",
    "menu10": "Cabling and Automation",
    "menu11": "Services and Operations Center (NOC and SOC)",
    "menu12": "Professional Services",
    "menu13": "Health",
    "menu14": "Retail",
    "menu15": "Professional Services",
    "menu16": "Logistics",
    "menu17": "Financial Services",
    "menu18": "Facilities",
    "title1": "More than a",
    "contTitle": "supplier,",
    "description": "we are business partners",
    "title2": "AVAIABLE",
    "contTitle2": "AND SECURE",
    "description2": "OPERATION MORE",
    "title3": "SMART",
    "contTitle3": "AND INNOVATIVE",
    "description3": "INFRASTRUCTURES",
    "sobre": "More than 9 years ago,",
    "sobre1": "Hexa has beem standing out on the national scene",
    "sobre2": "BEST PARTNER",
    "sobre3": "OF TECHNOLOGY",
    "sobre4": "VARIOUS COMPANIES",
    "sobre5": "Learn more",
   
    "vert1": "VERTICALS WE ACT FOR:",
    "vert2": "HEALTHCARE",
    "vert3": "RETAIL",
    "vert4": "PROFISSIONAL SERVICES",
    "vert5": "who we do it for?",
    "vert6": "RETAIL",
    "vert7": "FINANCIAL SERVICES",
    "vert8": "FACILITIES",
  },
}